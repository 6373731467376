html {
  background-color: #e8e8e8;
}
body {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 100 !important;
}

// body {
//   font-family: Poppins, sans-serif;
//   line-height: 1.5;
//   color: #212529;
//   background-color: #edf1f5;
//   font-weight: 300;
// }

// body.fontLoaded {
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 300;
// }

@font-face {
  font-family: themify;
  src: url(https://cdn.jsdelivr.net/npm/react-icons-themify@1.0.0/ti/user.js);

  // font-weight: 400;
  font-style: normal;
}

[class*=' ti-'],
[class^='ti-'] {
  font-family: themify;
  speak: none;
  font-variant: normal;
  text-transform: none;
}
#pre-loader {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  overflow: hidden !important;
  z-index: 1000000;
  opacity: 0.8;
}
.group-loader {
  text-align: center;
  left: -3%;
  position: absolute;
  right: 0;
  top: 48%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  z-index: 6;
  margin: 0 auto;
}
.form-wrapper {
  background-color: #fff !important;
  // background-origin: none !important;
}
.global-page .table-content {
  border: none;
}

.btn-full-screen {
  border: none;
  background: none;
  color: white;
  font-size: 25px;
}

@import '~animate.css';

textarea {
  resize: vertical !important;
}

nav.pagination {
  li.page-item {
    a.page-link {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      padding: 0.6rem 1rem;
      font-size: 14px;
      &:hover,
      &:active {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
      }
    }
    &.active {
      a.page-link {
        color: #fff;
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}

.pagination__item.page-item {
  display: none;
  &.active,
  &.prev-page,
  &.next-page {
    display: inline-block;
  }
}

.page-size-box {
  width: auto;
  margin-right: 30px;
  margin-bottom: 65px;
  .page-size-select {
    span {
      display: inline-block;
      font-weight: 600;
    }
    .react-select {
      width: 80px;
      display: inline-block;
    }
  }
}

.account-info {
  -webkit-box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
  box-shadow: 1px 2px 23px -3px rgba(0, 0, 0, 0.17);
  border-radius: 0px 0px 10px 12px !important;
  height: 30px;
  .account-detail {
    color: #000000 !important;
    padding: 5px 7px;
  }
}

// .red-color {
//   color: #dc3545;
// }

.xml-viewer {
  margin-top: 20px;
  background: white;
  border: 2px solid #dee2e6;
  padding: 10px;
}

.btn-view-file {
  min-width: 180px !important;
  // border: none;
}

.historical-folios-link {
  margin: auto;
  text-align: center;
  margin-top: -20px;
  font-size: 16px;
}

.modal-dialog.modal-custom {
  &.modal-80 {
    max-width: 80%;
  }
  &.modal-70 {
    max-width: 70%;
  }
  &.modal-60 {
    max-width: 60%;
  }
}

.select-focused {
  &.css-2b097c-container {
    z-index: 4 !important;
  }
}

.react-datepicker__header__dropdown {
  color: white;
  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown {
    color: #000000;
    font-weight: bold;
  }
}

.Toastify__toast-container {
  width: 400px !important;
}

.Toastify__toast-container--top-center {
  left: 48% !important;
}

@import './styles/styles.scss';
@import './styles/common.scss';
@import './styles/app.scss';

// base

@import '../node_modules/react-credit-cards/es/styles-compiled.css';
@import '../node_modules/react-step-progress-bar/styles.css';
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

.cardtop-credit-card {
  background-color: white !important;
  height: auto;
}