.make-payment-page {
  .make-payment-page__form {
    width: 99%;
    min-height: 100vh;
    margin: auto;
    position: relative;
    padding-top: 40px !important;
    padding-bottom: 50px;
    justify-content: center;
    margin-top: 120px;
    // overflow: scroll;
    display: flex;
    .form-payment {
      width: 99%;
      // background-color: transparent;
      border-radius: 0px;

      .header-sort {
        margin: auto;
        width: 98%;

        display: flex;

        .form__form-group-select {
        }
      }
    }
  }
}

.form-header {
  width: 100%;
  display: none;
}

.detail-name {
  padding-left: 5px;
  padding-right: 5px;
}

.make-payment-page .make-payment-page__form .form-wrapper .header-sort {
  margin: auto;

  display: none;
}

.make-payment-page .payment {
  padding: 0;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 203, 162, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #009c75;
  border-color: #008f6c;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #008f6c;
  border-color: #008262;
}

.btn-last {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
  width: 105px;
  padding-bottom: -20px;
}

.cardtop {
  width: 100%;
  background: #fff;
  height: auto;
  margin-bottom: 20px;
}

.col-lg-4 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  max-height: 206px;
}

.card-title {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 40px;
  padding-top: 20px;
}

.col-lg-4 h4 {
  margin-left: -35px;
}

.form-wrapper {
  border-radius: 2px;
  padding: 20px 10px;
  margin-top: -10px;
}

.card-title-payment {
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 40px;
  padding-top: 20px;
}

.title-card-payment {
  font-size: 20px;
}

.card-on-file {
  border-bottom: 1px solid;
  border-image-source: linear-gradient(43deg, #575e64, #e9ecef);
  border-image-slice: 1;
}

.btn-apply[disabled] {
  cursor: default;
  background-color: #999;
}
